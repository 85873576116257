.GetToken-login-button {
    margin-top: 150px !important;
}

.GetToken-text {
    margin-top: 100px !important;
    max-width: 80%;
}

.GetToken-spinner {
    position: absolute;
    width: 100px !important;
    height: 100px !important;
    margin-top: 350px;
}

.largearea {
    height: 600px;
}

.create__createbuttonspinner {
    position: absolute;
}

.create__createbuttonwrapper {
    position: relative;
}

.bold {
    font-weight: bold;
    font-size: 1.3rem;
}

/* add padding to answer_text  */
.answer_text {
    padding: 10px;
}